<template>
  <el-select
    v-model="text"
    filterable
    remote
    :disabled="disabled"
    :size="size"
    :placeholder="placeholder"
    style="width: 100%;"
    :remote-method="remoteMethod"
    :loading="loading"
    @change="handleChange"
  >
    <el-option
      v-for="item in remoteOptions"
      :key="item[dictValue]"
      :label="item[dictLabel]"
      :value="item[dictValue]"
    >
      <span>{{ item[dictLabel] }}</span>
      <!--<span style="margin-left: 20px;">{{ getSkuDetailStr(item) }}</span>-->
    </el-option>
  </el-select>
</template>

<script>
import propsMixin from "@/components/avue/mixins/propsMixin";
import {debounce} from "@/utils";
import { getProInfoList } from '@/api/product/checkProdApi'
import { validatenull } from '@/components/avue/utils/validate'

export default {
  name: "UserSkuSelect",
  mixins: [propsMixin],
  props: {
    props: {
      default: () => ({})
    },
    disabled: Boolean
  },
  data() {
    return {
      loading: false,
      remoteOptions: [],
    };
  },
  computed: {
    dictLabel() {
      return this.props.label || 'user_sku'
    },
    dictValue() {
      return this.props.value || 'user_sku'
    },
  },
  methods: {
    remoteMethod: debounce(
      async function(query) {
        if (query !== '') {
          this.loading = true
          
          try {
            let skuList = (await getProInfoList({
              externalSku: query
            }))?.detail
            if (!validatenull(skuList)) {
              let { dictLabel, dictValue } = this
              this.remoteOptions = [
                {
                  [dictLabel]: query,
                  [dictValue]: query,
                  specificProductId: skuList[0].id
                }
              ]
            } else {
              this.remoteOptions = []
            }
          } catch (e) {
            this.remoteOptions = []
          }
          
          // this.remoteOptions = (await getSkuList({sku: query})).detail
          this.loading = false
          
        } else {
          this.remoteOptions = []
        }
      },
      200
    ),
    getSkuDetailStr(sku) {
      return `产品名称:${sku.chinese_name} 款式:${sku.abbr}`
    },
    handleChange(value) {
      this.$emit('realValue', this.remoteOptions[0])
      this.$emit('input', value)
      this.$emit('change', value)
      this.$emit('search-change')
    }
  }
};
</script>

<style>
.is-readonly .el-input.is-disabled .el-input__inner{
  color: #606266;
  background-color: #fff;
  cursor: pointer;
}
.is-readonly .el-input.is-disabled .el-input__icon{
  cursor: pointer;
}
</style>
